import { CentralApiClient } from '@local/central-portal-core/dist/src/apiClients/centralApiClient/centralApiClient';
import { rtkQueryErrorLogger } from '@local/central-portal-core/dist/src/apiClients/rtkQueryErrorLogger';
import { reducer as visualizationReducer } from '@local/central-portal-core/dist/src/central/components/Visualization/context/reducer';
import { reducer as drawerReducer } from '@local/central-portal-core/dist/src/state/Drawer/DrawerState';
import { artifactState } from '@local/central-portal-core/dist/state-domains/domain/artifact';
import { attachmentsState } from '@local/central-portal-core/dist/state-domains/domain/attachments';
import { authState } from '@local/central-portal-core/dist/state-domains/domain/authentication';
import { branchState } from '@local/central-portal-core/dist/state-domains/domain/branch';
import { commentsState } from '@local/central-portal-core/dist/state-domains/domain/comments';
import { eventState } from '@local/central-portal-core/dist/state-domains/domain/event';
import { exportState } from '@local/central-portal-core/dist/state-domains/domain/export';
import { fileState } from '@local/central-portal-core/dist/state-domains/domain/file';
import { imdexState } from '@local/central-portal-core/dist/state-domains/domain/imdex';
import { integrationState } from '@local/central-portal-core/dist/state-domains/domain/integration';
import { licenseState } from '@local/central-portal-core/dist/state-domains/domain/license';
import { notificationsState } from '@local/central-portal-core/dist/state-domains/domain/notifications';
import { orgState } from '@local/central-portal-core/dist/state-domains/domain/org';
import { permissionState } from '@local/central-portal-core/dist/state-domains/domain/permission';
import { projectState } from '@local/central-portal-core/dist/state-domains/domain/project';
import { projectSceneState } from '@local/central-portal-core/dist/state-domains/domain/projectScene';
import { projectUserState } from '@local/central-portal-core/dist/state-domains/domain/projectUser';
import { publicSceneState } from '@local/central-portal-core/dist/state-domains/domain/publicScene';
import { publicScenesSettingsState } from '@local/central-portal-core/dist/state-domains/domain/publicScenesSettings';
import { revisionState } from '@local/central-portal-core/dist/state-domains/domain/revision';
import { sceneState } from '@local/central-portal-core/dist/state-domains/domain/scene';
import { sceneUsersState } from '@local/central-portal-core/dist/state-domains/domain/sceneUsers';
import { stageState } from '@local/central-portal-core/dist/state-domains/domain/stage';
import { toastState } from '@local/central-portal-core/dist/state-domains/domain/toast';
import { userState } from '@local/central-portal-core/dist/state-domains/domain/user';
import { xidState } from '@local/central-portal-core/dist/state-domains/domain/xid';
import { XidApiClient } from '@local/central-portal-core/dist/state-domains/domain/xid/service/AzureMapTokenApi';
import { middleware as loginMiddleware, reducers as loginReducers } from '@local/login';
import type { AnyAction, Reducer } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const combinedReducer = combineReducers({
    ...{
        // Add the generated reducer as a specific top-level slice
        [CentralApiClient.reducerPath]: CentralApiClient.reducer,
        [XidApiClient.reducerPath]: XidApiClient.reducer,
        artifact: artifactState.reducer,
        attachments: attachmentsState.reducer,
        authentication: authState.reducer,
        azureMpToken: xidState.reducer,
        branch: branchState.reducer,
        comments: commentsState.reducer,
        drawer: drawerReducer,
        event: eventState.reducer,
        export: exportState.reducer,
        file: fileState.reducer,
        imdex: imdexState.reducer,
        integration: integrationState.reducer,
        license: licenseState.reducer,
        notifications: notificationsState.reducer,
        org: orgState.reducer,
        permission: permissionState.reducer,
        project: projectState.reducer,
        projectScenes: projectSceneState.reducer,
        projectUser: projectUserState.reducer,
        publicScene: publicSceneState.reducer,
        publicScenesSettings: publicScenesSettingsState.reducer,
        revision: revisionState.reducer,
        scene: sceneState.reducer,
        sceneUsers: sceneUsersState.reducer,
        stage: stageState.reducer,
        toast: toastState.reducer,
        user: userState.reducer,
        visualization: visualizationReducer,
    },
    ...loginReducers,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'store/reset') {
        return {} as RootState;
    }
    return combinedReducer(state, action);
};

const devToolsConfiguration =
    process.env.NODE_ENV !== 'production'
        ? {
              serialize: {
                  options: undefined,
              },
          }
        : false;

export const createStore = () =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false,
            }).concat([
                XidApiClient.middleware,
                CentralApiClient.middleware,
                ...loginMiddleware,
                rtkQueryErrorLogger,
            ]),
        devTools: devToolsConfiguration,
    });

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
