import 'intersection-observer';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { getConfig, ProvideConfig } from '@local/app-config';
import { CentralPortal } from '@local/central-portal-core/dist/src/app';
import { GenericError } from '@local/central-portal-core/dist/src/components/GenericError';
import { getDefaultRoute } from '@local/central-portal-core/dist/src/routing/central/routing';
import { setBaseNotificationsUrl } from '@local/central-portal-core/dist/state-domains/utils/baseApiEndpoint';
import { ErrorBoundary, filterErrorEvent } from '@local/error-logging';
import { LandingPage, LoginHandler } from '@local/login';
import type { DatadogConfig } from '@local/metrics';
import { MetricsWrapper } from '@local/metrics';
import { theme } from '@local/web-design-system/dist/styles/theme';
import { ThemeProvider } from '@mui/material/styles';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { TssCacheProvider } from 'tss-react';

import LogoTitle from './assets/LogoTitle';
import { OrgInstanceRouteGuard } from './components/OrgInstanceRouteGuard';
import { store } from './store/reduxStore';
import { getDefaultRouteIncludingInstanceUuid } from './utils/selectedInstance';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageInfo = require('../package.json');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const centralPortalVersion: string = MARKETING_VERSION;

interface LaunchDarklyWrapperProps {
    children: JSX.Element;
    launchDarklyClientID: string;
}

const LaunchDarklyWrapper = ({ launchDarklyClientID, children }: LaunchDarklyWrapperProps) => {
    const LDProvider = withLDProvider({
        clientSideID: launchDarklyClientID,
        reactOptions: {
            useCamelCaseFlagKeys: false,
        },
    })(() => children);

    return <LDProvider />;
};

// Taken from https://app.datadoghq.com/rum/application/c9584249-b9df-4b3f-845d-b64f50e60a90
const datadogConfig: DatadogConfig = {
    appId: 'c9584249-b9df-4b3f-845d-b64f50e60a90',
    clientToken: 'pubac95b3c71be393ed4f471c709e0e6756',
    service: 'central-portal',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    version: centralPortalVersion,
};

const SetupCore = ({ children }: { children: JSX.Element }) => {
    // We only send data to sentry for release builds, not dev builds.
    const recordMetrics = process.env.NODE_ENV === 'production';
    const config = getConfig();
    setBaseNotificationsUrl(config.notificationsUrl);

    return config.launchDarklyClientID ? (
        <MetricsWrapper
            segmentWriteKey={config.central_segmentWriteKey}
            datadogConfig={recordMetrics ? datadogConfig : undefined}
        >
            <LaunchDarklyWrapper launchDarklyClientID={config.launchDarklyClientID}>
                {children}
            </LaunchDarklyWrapper>
        </MetricsWrapper>
    ) : (
        children
    );
};

const SetupLoginHandler = () => {
    const routes = (
        <Routes>
            <Route
                path=":instanceUuid/*"
                element={
                    <OrgInstanceRouteGuard>
                        <CentralPortal />
                    </OrgInstanceRouteGuard>
                }
            />
            <Route
                path="*"
                element={<Navigate to={getDefaultRouteIncludingInstanceUuid()} replace />}
            />
        </Routes>
    );

    return (
        <LoginHandler
            guardedRoutes={routes}
            store={store}
            LandingPage={LandingPage}
            LandingPageLogo={LogoTitle}
            service="central"
            homePagePath={getDefaultRoute()}
            forceOauthPrefix
            createId={false}
        />
    );
};

const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const tssCache = createCache({
    key: 'tss',
});

export const App = () => {
    // We only send data to sentry for release builds, not dev builds.
    const recordMetrics = process.env.NODE_ENV === 'production';

    return (
        <ProvideConfig>
            <IntlProvider locale="en-us" defaultLocale="en-us">
                <ErrorBoundary
                    errorComponent={<GenericError />}
                    // SENTRY_DSN is replaced at build time by webpack
                    // @ts-ignore
                    sentryDSN={recordMetrics ? SENTRY_DSN : undefined}
                    releaseVersion={packageInfo.version}
                    beforeSend={filterErrorEvent}
                >
                    <SetupCore>
                        <BrowserRouter basename="/">
                            <Provider store={store}>
                                <CacheProvider value={muiCache}>
                                    <TssCacheProvider value={tssCache}>
                                        <ThemeProvider theme={theme}>
                                            <SetupLoginHandler />
                                        </ThemeProvider>
                                    </TssCacheProvider>
                                </CacheProvider>
                            </Provider>
                        </BrowserRouter>
                    </SetupCore>
                </ErrorBoundary>
            </IntlProvider>
        </ProvideConfig>
    );
};

export const renderApp = async () => {
    document.title = 'Central';

    const container = document.getElementById('container') as HTMLElement;
    const root = ReactDOM.createRoot(container);
    root.render(<App />);
};
